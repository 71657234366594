import React, { useEffect } from "react";

export default function useSocket() {
  const [socket, setSocket] = React.useState(null);

  useEffect(() => {
    let relay = "wss://relay.damus.io";
    let socket = new WebSocket(relay);
    socket.onopen = function () {};

    socket.onerror = function (error) {
      console.error("Error: " + error);
    };

    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);

      // Check if the response is an error
      if (response.type === "ERROR") {
        console.error("Error received from relay:", response.message);
        // Handle the error (e.g., log it, notify the user, retry, etc.)
      } else {
        // console.log("Message received from relay:", response);
        // Handle other types of responses
      }
    };

    setSocket(socket);
  }, []);

  return socket;
}
