import React, { useEffect, useState } from "react";
import useReplies from "../../Hooks/useReplies";
import { convertHexToNoteId } from "../../Functions/Conversions";
import Post from "./Post";

export default function Replies({ post, profile, newReplies }) {
  const stringPostId = convertHexToNoteId(post.id);
  const replies = useReplies(stringPostId);
  const [allReplies, setAllReplies] = useState([]);

  useEffect(() => {
    let start = [];
    if (replies.length > 0) {
      start.push(...replies);
    }

    if (newReplies.length > 0) {
      start.push(...newReplies);
    }

    start.sort((a, b) => b.created_at - a.created_at);
    setAllReplies(start);
  }, [replies, newReplies]);

  return (
    <>
      {allReplies.map((reply, index) => {
        return (
          <div key={index}>
            <Post
              showReplyOption={false}
              post={reply}
              showReplies={true}
              isReply={true}
              replyUserName={profile.username}
              showParent={false}
            />
          </div>
        );
      })}
    </>
  );
}
