import React from "react";
import { Typography, Button } from "antd";
import DisplayInvoice from "../Video/DisplayInvoice";
import { useNavigate } from "react-router-dom";
import StormPubEmoji from "../Search/StormPubEmoji";

const { Paragraph } = Typography;

export default function Donate() {
  const navigate = useNavigate();
  return (
    <div className="m-1">
      <Typography.Title level={2}>
        Support <StormPubEmoji size={3} /> Storm.pub
      </Typography.Title>
      <Paragraph>
        Storm.pub is powered by users like you. We rely on the value for value model to keep the
        platform running. If you feel that you've received value from the platform, please consider
        sending some value back in the form of sats.
      </Paragraph>
      <Paragraph>
        Storm.pub hopes to build the platform based on the{" "}
        <Typography.Link
          onClick={() => {
            navigate("/about");
          }}
        >
          Roadmap
        </Typography.Link>
        . If you'd like to help make that happen, please select and amount and scan the invoice
        below using your lightning wallet.
      </Paragraph>
      <Paragraph>
        Donations will allow us to continue to build out the platform and add new features.
      </Paragraph>
      <Paragraph>Thank you.</Paragraph>
      <DisplayInvoice
        lightningAddress={"bitcoinrippin@strike.me"}
        makeItRain={true}
        verified={true}
        username={"bitcoinrippin"}
        defaultValue={69}
        donation={true}
      />
    </div>
  );
}
