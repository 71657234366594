import React from "react";
import { useParams } from "react-router-dom";
import useFollowList from "../../Hooks/useFollowList";
import Profile from "./Profile";

export default function Following() {
  const { npub } = useParams();
  const followList = useFollowList(npub);
  return (
    <div>
      {followList && followList.map((follow, index) => <Profile key={index} npub={follow.npub} />)}
    </div>
  );
}
