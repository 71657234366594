import React from "react";
import { Typography } from "antd";
import StormPubEmoji from "../Search/StormPubEmoji";

const { Paragraph } = Typography;

export default function Register() {
  return (
    <div className="m-1">
      <Typography.Title level={2}>How To Register</Typography.Title>
      <Paragraph>
        Currently, there are no "accounts", but if you have a YouTube channel, you can register it
        to receive tips. To register your channel, follow these steps:
      </Paragraph>
      <Paragraph>
        <ol>
          <li>
            Make sure that your lightning address is listed clearly in your YouTube channel bio.
          </li>
          <li>
            Send a message to{" "}
            <a href="https://www.twitter.com/bitcoinrippin" target="_blank" rel="noreferrer">
              @bitcoinrippin
            </a>{" "}
            on Twitter/X, requesting your account be activated. Make sure to specify your YouTube
            channel name. Alternatively, you can join the{" "}
            <a href="https://t.me/StormPubGroup" target="_blank" rel="noreferrer">
              <StormPubEmoji /> Storm.pub Telegram Group
            </a>{" "}
            and send a message there.
          </li>
          <li>I'll take a look and let you know when your channel is registered.</li>
        </ol>
      </Paragraph>
      <Paragraph>
        Once your channel is registered, you can receive tips from your viewers. If you have any
        questions, feel free to reach out on Twitter or in the Telegram Chat.
      </Paragraph>
    </div>
  );
}
