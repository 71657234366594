import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let size = "";
      if (width < 576) {
        size = "xs";
      } else if (width < 768) {
        size = "s";
      } else if (width < 992) {
        size = "m";
      } else if (width < 1200) {
        size = "l";
      } else {
        size = "xl";
      }
      setScreenSize(size);
    };

    // Initial call to set screen size
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
