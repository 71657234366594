import { Button } from "antd";
import React from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../UseComponents/UseScreenSize";

export default function FloatingPostButton() {
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const height = screenSize === "xs" || screenSize === "s" ? "5rem" : "3rem";

  return (
    <div
      style={{
        position: "fixed",
        bottom: height,
        width: "100%",
        right: "0",
        zIndex: "100",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1000px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            style={{
              width: "3rem",
              margin: "1rem",
              height: "3rem",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            onClick={() => {
              navigate("/post");
            }}
          >
            <FaPlus
              style={{
                fontSize: "1rem",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
}
