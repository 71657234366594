import React from "react";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
// import image svg
import logo from "./logo.svg";

export default function Logo({ level = 5, navBar = false, displayText = true }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: navBar ? "0rem" : "1rem",
        marginLeft: navBar ? "1rem" : "0rem",
      }}
      onClick={() => {
        if (navBar) {
          navigate("/");
        }
      }}
    >
      <img
        src={logo}
        alt="logo"
        style={{
          height: navBar ? "2rem" : "3rem",
          margin: "-1rem",
        }}
      />
      <Typography.Title
        level={level}
        style={{ marginBottom: "auto", marginTop: "auto", marginLeft: "1rem" }}
        onClick={() => {
          navigate("/");
        }}
      >
        {displayText && <>&nbsp;Storm.pub</>}
      </Typography.Title>
    </div>
  );
}
