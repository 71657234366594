import React, { useContext, useEffect } from "react";
import NdkContext from "../Context/NdkContext";
import { convertHexToNpub } from "../Functions/Conversions";

export default function useFollowList(npub) {
  const [followList, setFollowList] = React.useState(null);
  const { ndk } = useContext(NdkContext);

  useEffect(() => {
    async function fetchFollowList() {
      const user = ndk.getUser({ npub: npub });
      const follows = await user.follows();
      const userArray = Array.from(follows).map((user) => {
        return {
          npub: convertHexToNpub(user._pubkey),
          pubKey: user._pubkey,
        };
      });

      setFollowList(userArray);
    }

    if (npub && ndk) {
      fetchFollowList();
    }
  }, [npub, ndk]);

  return followList;
}
