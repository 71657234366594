import { Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import useReplies from "../../Hooks/useReplies";
import { convertHexToNoteId } from "../../Functions/Conversions";
import { FaBoltLightning, FaReply } from "react-icons/fa6";
import { FiShare } from "react-icons/fi";
import useReactions from "../../Hooks/useReactions";
import useReposts from "../../Hooks/useReposts";
import useZaps from "../../Hooks/useZaps";
import { useNavigate } from "react-router-dom";
import useProfile from "../../Hooks/useProfile";
import Replies from "./Replies";
import ParentNote from "./ParentNote";
import ActionIcon from "./ActionIcon";
import LikeButton from "./LikeButton";
import RepostButton from "./RepostButton";
import { message } from "antd";
import useIsVisible from "../../Hooks/useIsVisible";
import { BsThreeDotsVertical } from "react-icons/bs";
import RenderPostContent from "./RenderPostContent";
import { useLocation } from "react-router-dom";
import PostYourReply from "../../Components/PostYourReply";

export default function Post({
  post,
  showReplies = false,
  isReply = false,
  showParent = false,
  setPostIsVisible = () => {},
  showReplyOption,
}) {
  const stringPostId = convertHexToNoteId(post.id);
  const replies = useReplies(stringPostId);
  const reactions = useReactions(stringPostId);
  const reposts = useReposts(stringPostId);
  const zaps = useZaps(stringPostId);
  const profile = useProfile(post.pubKey, true);
  const navigate = useNavigate();
  const [hasParentNote, setHasParentNote] = useState(false);
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  const location = useLocation();
  const [showImageModal, setShowImageModal] = useState({
    visible: false,
    image: "",
  });
  const [newReplies, setNewReplies] = useState([]);

  useEffect(() => {
    console.log("newReplies", newReplies);
  }, [newReplies]);

  // if the post is visible, set the post as visible
  useEffect(() => {
    if (isVisible) {
      setPostIsVisible();
    }
  }, [isVisible, setPostIsVisible]);

  useEffect(() => {
    // console.log("isVisible", isVisible);
  }, [isVisible]);

  function isThisPostAReply() {
    return isReply || hasParentNote;
  }

  let sum = zaps.map((zap) => zap.amount).reduce((partialSum, a) => partialSum + a, 0);

  // format sum as human readable number
  if (sum > 1000000) {
    sum = (sum / 1000000).toFixed(2) + "M";
  } else if (sum > 1000) {
    sum = (sum / 1000).toFixed(2) + "K";
  }

  function navigateToNote() {
    if (location.pathname !== `/note/${stringPostId}`) {
      navigate(`/note/${stringPostId}`);
    }
  }

  function sharePost() {
    // copy the url to the clipboard
    navigator.clipboard.writeText(window.location.href);
    message.success("Copied the URL to the clipboard");
  }

  function navigateToProfile() {
    navigate(`/npub/${profile.npub}`);
  }

  return (
    <div ref={ref} key={post.id}>
      {profile && (
        <>
          {showParent && <ParentNote childPost={post} setIsParentVisible={setHasParentNote} />}
          {hasParentNote && (
            <div className="border-bottom pt-1 pl-1 pb-1">
              <Typography.Title
                level={5}
                style={{
                  margin: "0",
                }}
              >
                {profile.username}'s Reply
              </Typography.Title>
            </div>
          )}
          <div className="border-bottom">
            <div
              className="mt-1 mr-1 ml-1"
              style={{
                marginBottom: "0.5rem",
              }}
            >
              <div className={`mt-1 ${isThisPostAReply() ? "" : "mb-1"} flex-row`}>
                <img
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    marginBottom: "auto",
                    cursor: "pointer",
                  }}
                  src={profile.profileImageUrl}
                  alt={profile.username}
                  onClick={navigateToProfile}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <Typography.Title style={{ margin: "0" }} level={5} onClick={navigateToProfile}>
                      {profile.username}
                    </Typography.Title>
                    <div>
                      <Typography.Text type="secondary">
                        {moment.unix(post.created_at).format("MMM D")}
                      </Typography.Text>
                      <BsThreeDotsVertical
                        style={{
                          marginLeft: "1rem",
                          cursor: "pointer",
                          color: "gray",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(JSON.stringify(post));
                          message.success("Event JSON copied to the clipboard");
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "0.5rem",
                    }}
                  >
                    <Typography.Paragraph className="pointer" style={{ whiteSpace: "pre-line" }}>
                      <RenderPostContent
                        text={post.content}
                        navigateToNote={navigateToNote}
                        setImageForModal={setShowImageModal}
                        isVisible={isVisible}
                      />
                    </Typography.Paragraph>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                        }}
                      >
                        <ActionIcon
                          icon={<FaReply />}
                          count={replies.length}
                          onClick={navigateToNote}
                        />
                        <LikeButton reactions={reactions} post={post} />
                        <RepostButton post={post} reposts={reposts} />
                        <ActionIcon icon={<FaBoltLightning />} count={sum} />
                      </div>
                      <ActionIcon icon={<FiShare />} onClick={sharePost} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showReplyOption && (
            <PostYourReply
              post={post}
              addNewReply={(reply) => {
                setNewReplies((curr) => [...curr, reply]);
              }}
            />
          )}
          {showReplies && (
            <Replies post={post} profile={profile} showReplyOption={true} newReplies={newReplies} />
          )}
        </>
      )}
      {showImageModal.visible && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowImageModal({ visible: false, image: "" })}
          >
            <img
              src={showImageModal.image}
              alt="post"
              style={{
                width: "auto",
                height: "auto",
                maxHeight: "90%",
                maxWidth: "90%",
                objectFit: "contain",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
