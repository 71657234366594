// UserProvider.js
import React, { useState, useEffect } from "react";
import UserContext from "./UserContext";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  function getUserFromLocalStorage() {
    const user = localStorage.getItem("user");
    if (user) {
      const u = JSON.parse(user);
      setUser(u);
    } else {
      setUser(null);
    }
  }

  useEffect(() => {
    // load the user from local storage when the component mounts

    getUserFromLocalStorage();

    // listen for "user" events from the window
    window.addEventListener("user", getUserFromLocalStorage);

    return () => {
      window.removeEventListener("user", getUserFromLocalStorage);
    };
  }, []);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;
