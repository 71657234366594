import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import rain from "../../Images/rain.gif";
import useFollowList from "../../Hooks/useFollowList";
import { useNavigate } from "react-router-dom";

export default function Header({
  bannerUrl,
  profileImageUrl,
  username,
  description,
  website,
  npub,
  lud16,
  nip05,
}) {
  const [showBanner, setShowBanner] = useState(false);
  const followList = useFollowList(npub);
  const navigate = useNavigate();

  useEffect(() => {
    function testImage(URL) {
      var tester = new Image();
      tester.onload = () => setShowBanner(true);
      tester.onerror = () => setShowBanner(false);
      tester.src = URL;
    }

    testImage(bannerUrl);
  }, [bannerUrl]);

  return (
    <div>
      <img
        style={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
        }}
        src={showBanner ? bannerUrl : rain}
        alt="banner"
      />
      <div className="m-1">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "5rem",
              height: "5rem",
              borderRadius: "50%",
            }}
            src={profileImageUrl}
            alt="profile"
          />
          <div className="ml-1">
            <Typography.Title level={3}>{username}</Typography.Title>
            {website && (
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(`https://${website}`, "_blank");
                }}
              >
                <Typography.Paragraph className="text-secondary">{website}</Typography.Paragraph>
              </div>
            )}
          </div>
        </div>
        <div className="mt-1">
          <Typography.Paragraph
            className="text-secondary"
            style={{
              whiteSpace: "pre-line",
            }}
            ellipsis={{
              rows: 2,
              expandable: "collapsible",
            }}
          >
            {description}
          </Typography.Paragraph>
          <Button
            disabled
            style={{
              width: "100%",
            }}
          >
            Subscribe (coming soon)
          </Button>
        </div>
        <div className="mt-1">
          <Typography.Text
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/following/${npub}`);
            }}
            className="text-secondary hover-underline"
          >
            {followList ? followList.length : 0} Following
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}
