import React, { useContext, useEffect, useState } from "react";
import { Input, Typography } from "antd";
import useReplies from "../../Hooks/useReplies";
import Comment from "./Comment";
import UserContext from "../../Context/UserContext";
import { makeReply } from "../../Functions/Notes";
import { convertStringToHex } from "../../Functions/Conversions";
import SocketContext from "../../Context/SocketContext";
import Button from "../../Components/Button";

export default function Comments({ noteId }) {
  const comments = useReplies(noteId);
  const [postedComments, setPostedComments] = useState([]);

  const { user, setUser } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const [commentContent, setCommentContent] = useState("");

  async function postComment() {
    try {
      const pubKey = convertStringToHex(user.npub);
      const privateKey = user.nsec ? convertStringToHex(user.nsec) : null;
      const hexNoteId = convertStringToHex(noteId);

      const comment = await makeReply(socket, commentContent, pubKey, privateKey, hexNoteId);
      setPostedComments((curr) => [...curr, comment]);
    } catch (error) {
      console.error(error);
    } finally {
      setCommentContent("");
    }
  }

  return (
    <div>
      {user && (
        // {noteId && (
        <div className="mt-1 mb-1 card">
          <Typography.Title level={4}>Leave A Comment</Typography.Title>
          <Input
            placeholder="Comment"
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
            onPressEnter={(e) => {
              postComment();
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button type={"primary"} onClick={postComment} disabled={!commentContent}>
              Post
            </Button>
          </div>
        </div>
      )}

      {(comments.length > 0 || postedComments.length > 0) && (
        <div className="mt-1 mb-1 card">
          <Typography.Title level={4}>Comments</Typography.Title>
          <div>
            {[...comments, ...postedComments]
              .sort((a, b) => b.created_at - a.created_at)
              .map((comment, index) => (
                <div key={index}>
                  <Comment pubKey={comment.pubKey} content={comment.content} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
