import React, { useState, useEffect } from "react";
import useScreenSize from "../../UseComponents/UseScreenSize";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import RecentSearches from "./RecentSearches";
import Instructions from "./Instructions";
import SearchValue from "../../Hooks/searchValue";
import SearchBar from "../../Components/SearchBar";

export default function Search() {
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  const width = screenSize === "xs" || screenSize === "s" ? "75%" : "30rem";
  const maxWidth = "50rem";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showClearHistoryModal, setShowClearHistoryModal] = useState(true);

  // only show the clear history modal once. if the user clears the history, the modal will not show again
  // use localstorage to set a value. if the value is null, show the modal, once the user clears the history, set the value to true
  useEffect(() => {
    const show = localStorage.getItem("showClearHistoryModal");
    setShowClearHistoryModal(show === null || show === "true");
  }, []);

  // retrieve recent searches from localstorage if they exist
  const [recentSearches, setRecentSearches] = useState(() => {
    const recentSearches = localStorage.getItem("recentSearches");
    if (recentSearches) {
      const searches = JSON.parse(recentSearches);
      searches.reverse();
      return searches;
    } else {
      return [];
    }
  });

  function trySetLocalStorage(key, value, backupValue) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // local storage is full. clear it and try again
      localStorage.clear();
      localStorage.setItem(key, JSON.stringify(backupValue));
    }
  }

  function handleSearch(value) {
    // add value to recent searches in local storage
    let recentSearches = localStorage.getItem("recentSearches");
    if (recentSearches) {
      recentSearches = JSON.parse(recentSearches);

      // only keep the last 5 searches. Remove the oldest search
      if (recentSearches.length >= 5) {
        recentSearches.shift();
      }
      recentSearches.push(value);

      trySetLocalStorage("recentSearches", recentSearches, [value]);
    } else {
      trySetLocalStorage("recentSearches", [value], [value]);
    }
  }

  function navigateToYoutuebeLink(link) {
    const resource = SearchValue(link);
    navigate(resource);
    return;
  }

  function clearReacentSearches() {
    localStorage.removeItem("recentSearches");
    setRecentSearches([]);
    localStorage.setItem("showClearHistoryModal", "false");
    setShowClearHistoryModal(false);
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          height: "15rem",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="m-1 card"
          style={{
            width: width,
            maxWidth: maxWidth,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Logo level={2} />
          </div>

          <SearchBar
            width="100%"
            placeHolder="Youtube link e.g. https://www.youtube.com/watch?v=HDBSj9esSck"
            handleSearch={handleSearch}
          />
        </div>
      </div>
      {recentSearches.length > 0 ? (
        <RecentSearches
          recentSearches={recentSearches}
          clearReacentSearches={clearReacentSearches}
          handleSearch={(value) => {
            handleSearch(value);
            navigateToYoutuebeLink(value);
          }}
          setIsModalOpen={setIsModalOpen}
          showClearHistoryModal={showClearHistoryModal}
          width={width}
          maxWidth={maxWidth}
          isModalOpen={isModalOpen}
        />
      ) : (
        <Instructions width={width} maxWidth={maxWidth} />
      )}
    </div>
  );
}
