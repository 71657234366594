import React, { useEffect, useState } from "react";
import axios from "axios";
import YouTubeEmbed from "../../Components/YoutubeEmbed";
import { Typography, Drawer, Spin } from "antd";
import { useLocation } from "react-router-dom";
import TitleUserButtonSection from "./TitleUserButtonSection";
import DisplayInvoice from "./DisplayInvoice";
import { getApiDomain } from "../../App";
import SearchBar from "../../Components/SearchBar";
import Comments from "./Comments";
import CenterSpinner from "../../Components/CenterSpinner";

const { Paragraph } = Typography;

export default function Video() {
  const [videoData, setVideoData] = useState(null);
  const [invoiceVisible, setInvoiceVisible] = useState(false);
  const [makeItRain, setMakeItRain] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const videoId = query.get("v");

  useEffect(() => {
    async function fetchData() {
      // get message from the server
      try {
        const response = await axios.get(`${getApiDomain()}/videoDetails?youtubeId=${videoId}`);
        setVideoData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // createView();
  }, [videoId]);

  function likeButtonClicked() {
    if (invoiceVisible) {
      if (makeItRain) {
        setMakeItRain(false);
      } else {
        setInvoiceVisible(false);
      }
    } else {
      setInvoiceVisible(true);
      setMakeItRain(false);
    }
  }

  function rainButtonClicked() {
    if (invoiceVisible) {
      if (!makeItRain) {
        setMakeItRain(true);
      } else {
        setInvoiceVisible(false);
      }
    } else {
      setInvoiceVisible(true);
      setMakeItRain(true);
    }
  }

  return videoData === null ? (
    <>
      <CenterSpinner />
    </>
  ) : (
    <>
      <div className="mb-1 ml-1 mr-1">
        <SearchBar width="100%" />
      </div>
      <YouTubeEmbed videoId={videoId} />
      <div className="m-1">
        <TitleUserButtonSection
          title={videoData.details.title}
          profileImageUrl={videoData.details.profileImageUrl}
          username={videoData.details.channelTitle}
          subscribers={videoData.subscribers}
          likeButtonClicked={likeButtonClicked}
          rainButtonClicked={rainButtonClicked}
          verified={videoData.lightningAddress !== null && videoData.lightningAddress !== undefined}
          npub={videoData.npub}
        />

        <div className="mt-1 card">
          <Typography.Title level={5}>{videoData.viewCount} views</Typography.Title>
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: "collapsible",
              expanded,
              onExpand: (_, info) => {
                setExpanded(info.expanded);
              },
            }}
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {videoData.details.description}
          </Paragraph>
        </div>

        {videoData.noteId ? (
          <div>
            <Comments noteId={videoData.noteId} />
          </div>
        ) : (
          <>
            <div className="mt-1 mb-1 card">
              <Typography.Title level={4}>Comments</Typography.Title>
              <Paragraph disabled>(Coming soon)</Paragraph>
            </div>
          </>
        )}

        <Drawer
          title={makeItRain ? "Make it rain" : "Like this video with 21 sats"}
          placement="bottom"
          open={invoiceVisible}
          onClose={() => {
            setInvoiceVisible(false);
          }}
          height={makeItRain ? "40rem" : "30rem"}
        >
          <DisplayInvoice
            makeItRain={makeItRain}
            lightningAddress={videoData.lightningAddress}
            username={videoData.details.channelTitle}
            verified={videoData.lightningAddress !== null}
            defaultValue={makeItRain ? 69 : 21}
            videoId={videoId}
            donation={false}
          />
        </Drawer>
      </div>
    </>
  );
}
