import { Typography } from "antd";
import React from "react";

export default function ActionIcon({ icon, onClick, count = null, primary = false }) {
  const type = primary ? "primary" : "secondary";
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography.Text type={type}>{icon}</Typography.Text>
      {count !== null && count !== undefined && (
        <Typography.Text type={type}>{count}</Typography.Text>
      )}
    </div>
  );
}
