import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
export default function ProfileImageAndUsername({ profile }) {
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate(`/npub/${profile.npub}`);
  }

  return (
    <div className="flex-row pointer" onClick={navigateToProfile}>
      <img
        style={{
          width: "3rem",
          height: "3rem",
          borderRadius: "50%",
        }}
        src={profile.profileImageUrl}
        alt={profile.username}
      />
      <Typography.Title style={{ margin: "0" }} level={5}>
        {profile.username}
      </Typography.Title>
    </div>
  );
}
