import React, { useEffect, useState } from "react";
import useProfile from "../../Hooks/useProfile";
import useNote from "../../Hooks/useNote";
import ProfileImageAndUsername from "../../Components/ProfileImageAndUsername";
import Post from "../Channel/Post";
import Input from "../../Components/Input";

export default function NostrSearch() {
  const [text, setText] = useState("");
  const [npub, setNpub] = useState(null);
  const [noteId, setNoteId] = useState(null);
  const profile = useProfile(npub);
  const note = useNote(noteId);

  useEffect(() => {
    if (text.startsWith("npub") && text.length === 63) {
      setNpub(text);
      setNoteId(null);
    }
  }, [text]);

  useEffect(() => {
    if (text.startsWith("note") && text.length === 63) {
      setNoteId(text);
      setNpub(null);
    }
  }, [text]);

  useEffect(() => {
    if (!text) {
      setNpub(null);
      setNoteId(null);
    }
  }, [text]);

  return (
    <div className="m-1">
      <Input
        placeholder="Npub or NoteId"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
          }
        }}
      />
      <div className="mt-1">
        {profile && <ProfileImageAndUsername profile={profile} />}
        {note && <Post post={note} />}
      </div>
    </div>
  );
}
