import logo from "./logo.svg";

export default function StormPubEmoji({ size = 1.5 }) {
  const height = `${size}rem`;
  const margin = `${-size / 5}rem`;
  return (
    <img
      src={logo}
      alt="logo"
      style={{
        height: height,
        margin: margin,
      }}
    />
  );
}
