const profileCache = new Map();

/** TODO
 *
 * 1) Add date time that the profile was cached. if the cache
 * has more than X data, then start to remove the oldest entries
 *
 * 2) add a number for the amount of cache hits per profile. if a profile
 * has been hit many times, we probably dont want to remove it from the cache
 *
 * 3) add a datetime for each cache hit, so we can see when the profile was last
 * accessed. if a profile has not been accessed in a while, we can remove it from
 * the cache
 */

export const getCachedProfile = (npub) => {
  return profileCache.get(npub);
};

export const setCachedProfile = (npub, profile) => {
  profileCache.set(npub, profile);
};
