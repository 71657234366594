import React from "react";
import WritePost from "../Timeline/WritePost";

export default function Post() {
  return (
    <div>
      <WritePost />
    </div>
  );
}
