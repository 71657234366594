import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function LocationBar() {
  // get the current location
  const location = useLocation();
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    if (location.pathname === "/") {
      document.title = "Timeline";
      setTitle("Timeline");
      return;
    }

    if (location.pathname.startsWith("/npub")) {
      document.title = "Profile";
      setTitle("Profile");
      return;
    }

    if (location.pathname.startsWith("/following")) {
      document.title = "Following";
      setTitle("Following");
      return;
    }

    if (location.pathname.startsWith("/note")) {
      document.title = "Note";
      setTitle("Note");
      return;
    }

    if (location.pathname.startsWith("/post")) {
      document.title = "New Post";
      setTitle("New Post");
      return;
    }

    if (location.pathname.startsWith("/settings")) {
      document.title = "Settings";
      setTitle("Settings");
      return;
    }

    if (location.pathname.startsWith("/nostrsearch")) {
      document.title = "Search";
      setTitle("Search");
      return;
    }

    if (location.pathname.startsWith("/search")) {
      document.title = "Search";
      setTitle("Search");
      return;
    }

    // else just set the title to the pathname (uppercase)
    let path = location.pathname;
    path = path.charAt(1).toUpperCase() + path.slice(2);
    document.title = path;
    setTitle(path);
  }, [location]);

  return (
    <div className=" border-bottom ">
      <div className="m-1">
        <div
          style={{
            position: "relative",
          }}
        >
          {location.pathname !== "/" && (
            <div
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "50%",
                transform: "translateY(-50%)",
                width: "24px",
                height: "24px",
              }}
              onClick={goBack}
            >
              <IoArrowBack
                style={{
                  fontSize: "1.5rem",
                }}
              />
            </div>
          )}
          <div className="m-1 text-center">
            <Typography.Title
              style={{
                margin: "0",
              }}
              level={4}
            >
              {title}
            </Typography.Title>
          </div>
        </div>
      </div>
    </div>
  );
}
