import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NdkContext from "../../Context/NdkContext";
import { convertStringToHex } from "../../Functions/Conversions";
import Link from "antd/es/typography/Link";

export default function NostrUsername({ npub }) {
  const navigate = useNavigate();
  const { ndk, setNdk } = useContext(NdkContext);
  const [userName, setUserName] = useState("");
  const [trailingCharacters, setTrailingCharacters] = useState("");

  async function fetchUserName(npub) {
    return fetchProfileData(npub).then((data) => {
      return data.username;
    });
  }

  async function fetchProfileData(npub) {
    const pubkey = convertStringToHex(npub);
    const u = ndk.getUser({
      pubkey: pubkey, // THIS MUST BE HEX
    });

    const p = await u.fetchProfile();
    if (!p) {
      return;
    }
    const userInfo = {
      bannerUrl: p.banner,
      profileImageUrl: p.image,
      username: p.displayName === null || p.displayName === "" ? p.name : p.displayName,
      description: p.about,
      website: p.website,
      lud16: p.lud16,
      nip05: p.nip05,
      npub: npub,
      pubKey: npub,
    };

    return userInfo;
  }

  useEffect(() => {
    if (npub) {
      let newNpub = npub;

      // catch any trailing characters after the npub. the lenght of the npub is 63 characters
      if (npub.length > 63) {
        newNpub = npub.substring(0, 63);
        setTrailingCharacters(npub.substring(63));
      }
      try {
        fetchUserName(newNpub).then((data) => {
          setUserName(data);
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [npub]);

  return (
    <>
      <Link onClick={() => navigate(`/npub/${npub}`)}> @{userName}</Link>
      {trailingCharacters}
    </>
  );
}
