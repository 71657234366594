import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../Context/UserContext";
import useFollowList from "../../Hooks/useFollowList";
import CenterSpinner from "../../Components/CenterSpinner";
import fetchEvents from "../../Functions/FetchEvents";
import NdkContext from "../../Context/NdkContext";
import Post from "../Channel/Post";
import InfiniteScroll from "react-infinite-scroll-component";
import WritePost from "./WritePost";
import useIsLoggedIn from "../../Hooks/useIsLoggedIn";
import { Typography } from "antd";
import FloatingPostButton from "../../Components/FloatingPostButton";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

export default function Timeline() {
  const { user } = useContext(UserContext);
  const followList = useFollowList(user?.npub);
  const { ndk } = useContext(NdkContext);
  const latestBatch = useRef(null);
  const isLoggedIn = useIsLoggedIn();
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  // NOTE
  // have to use this weird state staging thing because the infinite scroll component
  // doesn't like it when you set posts to an empty object and then add to it
  const [postsStaging, setPostsStaging] = useState(null);
  const [posts, setPosts] = useState(null);

  const initialLoadBatchSize = 20; // small initial load to get the page to render fast
  const subsequentLoadBatchSize = 500; // large batch size for subsequent loads necessary to not skip posts
  const oneHour = 3600;

  function getMostRecentPostFromLatestBatch(batch) {
    let postsCopy = { ...batch };
    let sorted = Object.values(postsCopy).sort((a, b) => b.created_at - a.created_at);
    if (sorted.length === 0) {
      return null;
    }
    return sorted[0];
  }

  function calculateUntil(created_at, interval) {
    let numHours = 2 ** (interval - 1);
    let totalSeconds = numHours * oneHour;
    const until = created_at - totalSeconds - 1;
    return until; // remove one second to prevent duplicates
  }

  // 1 hour, 2 hours, 4 hours, 8 hours, etc
  function calculateSince(created_at, interval) {
    let numHours = 2 ** interval;
    let totalSeconds = numHours * oneHour;
    const since = created_at - totalSeconds;
    return since;
  }

  function filterOutReplies(posts) {
    return Object.fromEntries(
      Object.entries(posts).filter(([key, value]) => {
        if (value.tags.length === 0) {
          return true;
        } else {
          // check if any of the tags start with "e"
          const isNotReply = value.tags.filter((tag) => tag[0] === "e").length === 0;
          return isNotReply;
        }
      })
    );
  }

  function printHoursAgo(created_at, until, since) {
    let untilHoursInPast = (created_at - (until + 1)) / oneHour;
    let sinceHoursInPast = (created_at - since) / oneHour;
    console.log(`fetching posts from ${sinceHoursInPast} to ${untilHoursInPast} hours ago`);
  }

  useEffect(() => {
    if (postsStaging && Object.keys(postsStaging).length < 10 && hasMore) {
      fetchProgressivelyOlderPosts();
    } else {
      setPosts(postsStaging);
    }
  }, [postsStaging, hasMore]);

  /**
   * fetch posts first starting
   * with the most recent 1 hour, then 2 hours, then 4 hours, etc, doubling each time
   * using the created_at of the MOST RECENT post fetched from the latest batch of posts,
   * and the "since" and "until" filters. if no posts are found, increase the interval
   * and try again. if no posts are found after a certain number of tries, stop.
   */
  async function fetchProgressivelyOlderPosts() {
    for (let i = 1; i < 14; i++) {
      // up to 2^14 hours
      const mostRecentPost = getMostRecentPostFromLatestBatch(latestBatch.current); // TODO what if this is null

      let curentTimeUnix = Math.floor(new Date().getTime() / 1000);

      let until = calculateUntil(mostRecentPost ? mostRecentPost.created_at : curentTimeUnix, i);
      let since = calculateSince(mostRecentPost ? mostRecentPost.created_at : curentTimeUnix, i);
      // printHoursAgo(mostRecentPost.created_at, until, since);

      let newPosts = await fetchPosts(
        followList.map((f) => f.pubKey),
        subsequentLoadBatchSize,
        since,
        until
      );

      // filter out posts that are replies. replies have tags with [0] = "e"
      newPosts = filterOutReplies(newPosts);

      if (Object.keys(newPosts).length > 0) {
        // diff the new posts with the existing posts. make two sets from the keys and compare
        let postsCopy = { ...postsStaging };
        let newPostsKeys = Object.keys(newPosts);
        let postsKeys = Object.keys(postsCopy);
        let diff = newPostsKeys.filter((key) => !postsKeys.includes(key));

        // if there are new posts, add them to the existing posts, else try the next interval
        if (Object.keys(diff).length === 0) {
          // console.log("no new posts found, trying next interval");
          latestBatch.current = newPosts;
          continue;
        }

        // get a new object with just the diff keys
        let diffPosts = {};
        diff.forEach((key) => {
          diffPosts[key] = newPosts[key];
        });

        const allPosts = { ...postsStaging, ...diffPosts };
        latestBatch.current = postsStaging;
        setPostsStaging(allPosts);
        setHasMore(true);
        return;
      } else {
        // console.log("no posts found for this interval, trying next interval");
      }
    }

    // if we get here, we have tried all intervals and found no new posts
    setHasMore(false);
  }

  async function fetchPosts(pubKeys, batchSize, since = null, until = null, initial = false) {
    let filter = {
      kinds: [1],
      authors: pubKeys,
      limit: batchSize,
    };

    if (since) {
      filter.since = since;
    }

    if (until) {
      filter.until = until;
    }

    let events = await fetchEvents(ndk, filter);

    if (initial) {
      // sort the events by created_at
      events.sort((a, b) => b.created_at - a.created_at);
    }

    return formatEvents(events);
  }

  function formatEvents(events) {
    let formattedPosts = {};
    events.forEach((event) => {
      formattedPosts[event.id] = event;
    });

    return formattedPosts;
  }

  async function fetchInitialLoad(followList) {
    if (followList && ndk) {
      let gatheredPosts = await fetchPosts(
        followList.map((f) => f.pubKey),
        initialLoadBatchSize,
        null,
        null,
        true
      );

      const newPosts = filterOutReplies(gatheredPosts);

      latestBatch.current = newPosts;
      setPostsStaging(newPosts);
    }
  }

  useEffect(() => {
    fetchInitialLoad(followList);
  }, [followList, ndk]);

  function postBecameVisible() {
    // console.log("post became visible");
  }

  return (
    <div>
      {isLoggedIn ? (
        <div>
          {!posts && <CenterSpinner />}
          {posts && (
            <div>
              <FloatingPostButton />
              <InfiniteScroll
                dataLength={Object.keys(posts).length}
                next={fetchProgressivelyOlderPosts}
                hasMore={hasMore}
                loader={<CenterSpinner />}
                endMessage={
                  <div className="m-1">
                    <Typography.Paragraph style={{ textAlign: "center" }}>
                      No more posts to show.
                    </Typography.Paragraph>
                  </div>
                }
                // below props only if you need pull down functionality
                refreshFunction={() => {
                  setPostsStaging(null);
                  setPosts(null);
                  fetchInitialLoad(followList);
                }}
                pullDownToRefresh
                pullDownToRefreshThreshold={100}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
                }
              >
                <WritePost />
                {Object.keys(posts).map((key) => (
                  <Post
                    key={key}
                    post={posts[key]}
                    setPostIsVisible={postBecameVisible}
                    showReplyOption={false}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            color: "gray",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Paragraph>Please log in to use the app</Typography.Paragraph>
          <Button
            style={{
              width: "auto",
              margin: "auto",
            }}
            type="primary"
            className="mt-1"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </Button>
        </div>
      )}
    </div>
  );
}
