import { Spin } from "antd";
import React from "react";

export default function CenterSpinner() {
  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Spin />
    </div>
  );
}
