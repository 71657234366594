import { Typography } from "antd";
import React from "react";
import StormPubEmoji from "../Search/StormPubEmoji";

export default function About() {
  return (
    <div className="m-1">
      <Typography.Title level={2}>
        About <StormPubEmoji size={3} /> Storm.Pub
      </Typography.Title>
      <Typography.Paragraph>
        Storm.pub is a platform that allows you to watch any video on YouTube and send sats directly
        to the creator without any fees. The sats are sent directly to the content creator's wallet.
      </Typography.Paragraph>
      <Typography.Title level={3}>Features</Typography.Title>
      <Typography.Paragraph>
        <ul>
          <li>
            <b>Like</b> - displays a lightning invoice for 21 sats directly to the creators wallet.{" "}
          </li>
          <li>
            <b>Make It Rain</b> - allows you to enter how many sats you'd like to send to the
            creator.
          </li>
          <li>
            <b>Watch any YouTube video</b> - simply paste the link in the search bar and hit enter.
          </li>
          <li>
            <b>Verified Accounts</b> - YouTube accounts that have set up a lightning address will
            display a ✅ next to their username.
          </li>
        </ul>
      </Typography.Paragraph>
      <Typography.Title level={3}>Roadmap</Typography.Title>
      <Typography.Title level={4}>Nostr Client</Typography.Title>
      <Typography.Paragraph>
        Storm.pub hopes to build out future features on top of the Nostr network. Once Nostr is
        integrated, videos can be posted within the Nostr network, and accounts on Storm.pub will be
        linked to Nostr.
      </Typography.Paragraph>

      <Typography.Paragraph>
        Nostr will allow Storm.pub have a decentralized database for content. This will allow:
        <ul>
          <li>✅ Nostr Login</li>
          <li>✅ Nostr Profiles</li>
          <li>✅ Nostr Timeline</li>
          <li>✅ Nostr Posts</li>
          <li>✅ Comment section</li>
          <li>✅ Likes</li>
          <li>Hashtags</li>
          <li>Mentions</li>
          <li>Image Posting</li>
          <li>Image Previews</li>
          <li>Zap videos directly, and display the amount of sats zapped</li>
          <li>Subscriptions</li>
          <li>Notifications</li>
          <li>For creators, post videos to Nostr instead of YouTube</li>
        </ul>
      </Typography.Paragraph>
    </div>
  );
}
