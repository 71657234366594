import React from "react";
import { Typography } from "antd";
import StormPubEmoji from "./StormPubEmoji";

export default function Instructions({ width, maxWidth }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        width: width,
        maxWidth: maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography.Paragraph>1. Go to a youtube video 🎥</Typography.Paragraph>
      <Typography.Paragraph>2. Click share 🖱️ and copy the link</Typography.Paragraph>
      <Typography.Paragraph>
        3. Paste the link in the <StormPubEmoji /> Storm.pub search bar and hit enter
      </Typography.Paragraph>
      <Typography.Paragraph>4. Support your favorite creators with ⚡ sats</Typography.Paragraph>
    </div>
  );
}
