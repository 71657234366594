import React, { useEffect, useState } from "react";
import { convertHexToNoteId } from "../../Functions/Conversions";
import useNote from "../../Hooks/useNote";
import Post from "./Post";

export default function ParentNote({ childPost, setIsParentVisible = () => {} }) {
  const [parentNoteId, setParentNoteId] = useState(null);
  const parentNote = useNote(parentNoteId);

  useEffect(() => {
    if (childPost.tags) {
      const replyTags = childPost.tags.filter((tag) => tag[0] === "e");
      const tag = replyTags.length > 0 ? replyTags[0] : null;
      if (tag) {
        const noteIdHex = tag[1];
        const noteIdString = convertHexToNoteId(noteIdHex);
        setParentNoteId(noteIdString);
        setIsParentVisible(true);
      } else {
        setParentNoteId(null);
        setIsParentVisible(false);
      }
    }
  }, [childPost.tags, setIsParentVisible]);

  return (
    <>
      {parentNote && (
        <>
          <Post post={parentNote} showReplies={false} showParent={false} showReplyOption={false} />
        </>
      )}
    </>
  );
}
