// convert the share link to a watch link
// ex https://youtu.be/H-F1zalGzng?si=NS73aCvR9HsCMrAV to https://www.youtube.com/watch?v=H-F1zalGzng
export default function SearchValue(value) {
  if (value.includes("https://youtu.be")) {
    const resource = value.replace("https://youtu.be", "").split("?")[0].replace("/", "");
    value = `https://www.youtube.com/watch?v=${resource}`;
  }

  if (value.includes("https://www.youtube.com/watch?v=")) {
    const resource = value.replace("https://www.youtube.com", "");
    return resource;
  }

  return value;
}
