import React, { useContext } from "react";
import { FaHome, FaUser, FaSearch } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../UseComponents/UseScreenSize";
import UserContext from "../Context/UserContext";

export default function HomeRow({ children }) {
  const { user } = useContext(UserContext);
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  const height = screenSize === "xs" || screenSize === "s" ? "5rem" : "3rem";

  return (
    <>
      <div>
        {children}
        <div
          style={{
            height: height,
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          className="homerow"
          style={{
            position: "fixed",
            width: "100%",
            maxWidth: "1000px",
            height: height,
            bottom: "0",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-around",
              margin: "1rem",
              width: "100%",
              fontSize: "1rem",
            }}
          >
            <FaHome
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            <FaSearch
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/nostrsearch");
              }}
            />
            <FaUser
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate(`/npub/${user?.npub}`);
              }}
            />
            <FaGear
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/settings");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
