import React from "react";
import { Input as InputAlias } from "antd";

export default function Input({ placeholder, value, onChange, onKeyDown }) {
  return (
    <InputAlias
      placeholder={placeholder}
      style={{
        width: "100%",
        height: "3rem",
        borderRadius: "1rem",
        border: "none",
        padding: "1rem",
      }}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}
