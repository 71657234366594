import React from "react";
import useProfile from "../../Hooks/useProfile";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

export default function Profile({ npub }) {
  const profile = useProfile(npub);
  const navigate = useNavigate();
  return (
    <div>
      {profile && (
        <div
          className={`m-1 flex-row`}
          style={{
            justifyContent: "space-between",
          }}
          onClick={() => {
            navigate(`/npub/${npub}`);
          }}
        >
          <div className="flex-row pointer">
            <img
              style={{
                width: "3rem",
                height: "3rem",
                borderRadius: "50%",
              }}
              src={profile.profileImageUrl}
              alt={profile.username}
            />
            <Typography.Title style={{ margin: "0" }} level={5}>
              {profile.username}
            </Typography.Title>
          </div>
        </div>
      )}
    </div>
  );
}
