import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Post from "./Post";
import { convertStringToHex } from "../../Functions/Conversions";
import NdkContext from "../../Context/NdkContext";
import CenterSpinner from "../../Components/CenterSpinner";
import fetchEvents from "../../Functions/FetchEvents";
import { Typography } from "antd";

export default function PostsLazyLoad({ npubArray, showReplies = true }) {
  const { ndk } = useContext(NdkContext);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  async function fetchPosts(option) {
    if (option !== "latest" && option !== "until")
      throw new Error("option must be 'latest' or 'until'");

    // npubArray = ["npub1sg6plzptd64u62a878hep2kev88swjh3tw00gjsfl8f237lmu63q0uf63m"];
    let authors = npubArray.map((npub) => convertStringToHex(npub));

    // by default omit a 'until' or 'until' filter
    let filter = {
      kinds: [1],
      authors: authors,
      limit: 10,
    };

    // todo possible that earliest post is null...
    if (option === "until") {
      let postsCopy = [...posts];
      let sorted = postsCopy.sort((a, b) => a.created_at - b.created_at);
      let earliestDate = sorted[0];
      filter.until = earliestDate.created_at - 1; // one second prior because the filter is <=
    }

    let formattedEvents = await fetchEvents(ndk, filter);
    let formattedPosts = [];
    formattedEvents.forEach((event) => {
      if (event.content) {
        formattedPosts.push(event);
      }
    });

    formattedPosts.sort((a, b) => b.created_at - a.created_at);
    setPosts((curr) => [...curr, ...formattedPosts]);
    setHasMore(formattedPosts.length > 0);
  }

  useEffect(() => {
    if (npubArray && ndk) {
      fetchPosts("latest");
    }
  }, [npubArray, ndk]);

  return (
    <>
      <div>
        <>
          {posts && (
            <InfiniteScroll
              dataLength={posts.length}
              next={() => {
                fetchPosts("until");
              }}
              hasMore={hasMore}
              loader={<CenterSpinner />}
              endMessage={
                <div className="m-1">
                  <Typography.Paragraph className="text-center ">
                    No more posts to show
                  </Typography.Paragraph>
                </div>
              }
            >
              {posts.map((post) => {
                return <Post key={post.id} post={post} showReplyOption={false} />;
              })}
            </InfiniteScroll>
          )}
        </>
      </div>
    </>
  );
}
