export default async function fetchEvents(ndk, filter) {
  try {
    const events = await ndk.fetchEvents(filter);
    const eventArray = Array.from(events);
    if (eventArray.length === 0) {
      return [];
    }
    let formattedEvents = [];
    eventArray.forEach((event) => {
      if (event.content) {
        formattedEvents.push({
          id: event.id,
          content: event.content,
          pubKey: event.pubkey,
          created_at: event.created_at,
          tags: event.tags,
          url: event.relay.url,
        });
      }
    });

    return formattedEvents;
  } catch (error) {
    console.error(error);
  }
}
