import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Menu, Row, Spin } from "antd";
import Header from "./Header";
import { GiOstrich } from "react-icons/gi";
import { FaVideo } from "react-icons/fa6";
import Video from "./Video";
import useProfile from "../../Hooks/useProfile";
import useVideos from "../../Hooks/useVideos";
import PostsLazyLoad from "./PostsLazyLoad";
import CenterSpinner from "../../Components/CenterSpinner";
import WritePost from "../Timeline/WritePost";
import FloatingPostButton from "../../Components/FloatingPostButton";

export default function ChannelPage() {
  const { npub } = useParams();
  const channelData = useProfile(npub);
  const videos = useVideos(npub);
  const [current, setCurrent] = useState("nostr");

  const items = [
    {
      label: "Videos",
      key: "videos",
      icon: <FaVideo />,
    },
    {
      label: "Posts",
      key: "nostr",
      icon: <GiOstrich />,
    },
  ];

  return (
    <>
      {channelData && videos ? (
        <>
          <Header
            bannerUrl={channelData.bannerUrl}
            profileImageUrl={channelData.profileImageUrl}
            username={channelData.username}
            customUrl={channelData.customUrl}
            description={channelData.description}
            website={channelData.website}
            lud16={channelData.lud16}
            nip05={channelData.nip05}
            npub={npub}
          />
          <Menu
            className="background"
            onClick={(e) => {
              setCurrent(e.key);
            }}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
          <Row>
            <Col span={24}>
              {current === "videos" && (
                <>
                  {videos && videos.length === 0 && <div className="card m-1">No videos found</div>}
                  {videos && videos.map((video, index) => <Video key={index} video={video} />)}
                </>
              )}
              {current === "nostr" && (
                <>
                  <WritePost />
                  <FloatingPostButton />
                  <PostsLazyLoad npubArray={[npub]} />
                </>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <CenterSpinner />
      )}
    </>
  );
}
