import React from "react";
import YouTube from "react-youtube";

export default function YouTubeEmbed({ videoId }) {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
      }}
    >
      <YouTube
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        videoId={videoId}
        opts={opts}
      />
    </div>
  );
}
