import { bech32 } from "bech32";
import buffer from "buffer"; // required for bech32 toWords to work with

const bytesToHex = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

export function convertStringToHex(noteId) {
  let decoded = bech32.decode(noteId);
  let words = bech32.fromWords(decoded.words);
  let hex = bytesToHex(words);
  return hex;
}

export function convertHexToNoteId(hex) {
  return bech32.encode("note", bech32.toWords(buffer.Buffer.from(hex, "hex")));
}

export function convertHexToNpub(hex) {
  return bech32.encode("npub", bech32.toWords(buffer.Buffer.from(hex, "hex")));
}
