import React from "react";
import { Button as ButtonAlias } from "antd";

export default function Button({ onClick, children, className, type, style, disabled }) {
  return (
    <ButtonAlias
      onClick={onClick}
      className={className}
      type={type}
      style={{
        borderRadius: "1rem",
        border: "none",
        width: "auto",
        ...style,
      }}
      disabled={disabled}
      size="large"
    >
      <div style={{}}>{children}</div>
    </ButtonAlias>
  );
}
