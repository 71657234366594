import React from "react";
import { Typography } from "antd";
import StormPubEmoji from "../Search/StormPubEmoji";

const { Paragraph } = Typography;

export default function Contact() {
  return (
    <div className="m-1">
      <Typography.Title level={2}>Contact</Typography.Title>
      <Paragraph>
        Join the{" "}
        <a href="https://t.me/StormPubGroup" target="_blank" rel="noreferrer">
          <StormPubEmoji /> Storm.pub Telegram Group
        </a>{" "}
        to get in touch with the developer and other users.
      </Paragraph>
      <Paragraph>
        Follow me on twitter{" "}
        <a href="https://twitter.com/bitcoinrippin" target="_blank" rel="noreferrer">
          @bitcoinrippin
        </a>{" "}
        for updates and news.
      </Paragraph>
      <Typography.Title level={3}>Other Projects</Typography.Title>
      <Paragraph>
        Check out my freelance marketplace for Bitcoiners at{" "}
        <a href="https://www.BitcoinerFreelance.com" target="_blank" rel="noreferrer">
          BitcoinerFreelance.com
        </a>
      </Paragraph>
    </div>
  );
}
