import React, { useContext, useEffect } from "react";
import { convertStringToHex } from "../Functions/Conversions";
import NdkContext from "../Context/NdkContext";
import fetchEvents from "../Functions/FetchEvents";

export default function useReposts(noteId) {
  const [reposts, setReposts] = React.useState([]);
  const { ndk, setNdk } = useContext(NdkContext);

  useEffect(() => {
    async function fetchReposts() {
      let hex = convertStringToHex(noteId);

      const filter = {
        kinds: [6],
        "#e": [hex],
      };

      const formattedEvents = await fetchEvents(ndk, filter);
      let formattedReposts = [];
      formattedEvents.forEach((event) => {
        if (event.content) {
          formattedReposts.push(event);
        }
      });

      setReposts(formattedReposts);
    }

    fetchReposts();
  }, [noteId, ndk]);

  return reposts;
}
