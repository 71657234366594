import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useNote from "../../Hooks/useNote";
import useProfile from "../../Hooks/useProfile";
import { Spin, Typography } from "antd";
import Post from "./Post";
import CenterSpinner from "../../Components/CenterSpinner";

export default function Note() {
  const { noteId } = useParams();
  const note = useNote(noteId);
  const profile = useProfile(note?.pubKey, true);

  return (
    <div>
      {note && profile ? (
        <Post
          post={note}
          profileImageUrl={profile.profileImageUrl}
          showReplies={true}
          showParent={true}
          showReplyOption={true}
        />
      ) : (
        <>
          <CenterSpinner />
        </>
      )}
    </div>
  );
}
