import React, { useContext, useEffect, useState } from "react";
import { Input, Typography, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import NDK from "@nostr-dev-kit/ndk";
import Button from "../../Components/Button";
import { convertHexToNpub } from "../../Functions/Conversions";

const { Paragraph } = Typography;

export default function Login() {
  const [npub, setNpub] = useState("");
  const [nsec, setNsec] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    async function loadNpubFromNos2x() {
      const npubHex = await window.nostr.getPublicKey();
      if (npubHex) {
        const n = convertHexToNpub(npubHex);
        setNpub(n);
      }
    }
    if (window.nostr) {
      loadNpubFromNos2x();
    }
  }, []);

  useEffect(() => {
    if (npub) {
      setButtonEnabled(true);
    }
  }, [npub]);

  useEffect(() => {
    if (npub && npub.startsWith("npub")) {
      const shortenedNpub = npub.slice(0, 10) + "..." + npub.slice(-10);
      setMessage(shortenedNpub);
    } else if (npub) {
      setMessage("Invliad npub");
    }
  }, [npub]);

  async function storeNpubInLocalStorage() {
    setButtonEnabled(false);
    // delete npub and npub from local storage
    localStorage.removeItem("user");

    if (npub) {
      try {
        const ndk = new NDK({
          explicitRelayUrls: ["wss://relay.damus.io", "wss://purplepag.es"],
        });
        // Now connect to specified relays
        await ndk.connect();
        const u = ndk.getUser({
          npub: npub,
        });
        const p = await u.fetchProfile();
        const userInfo = {
          about: p.about,
          banner: p.banner,
          displayName: p.displayName,
          image: p.image,
          lud16: p.lud16,
          name: p.name,
          nip05: p.nip05,
          website: p.website,
          npub: npub,
          nsec: nsec,
        };
        localStorage.setItem("user", JSON.stringify(userInfo));
        // setUser(userInfo);

        navigate("/");
      } catch (error) {
        // alert error message
        alert("Invalid npub");
      }
    } else {
      // alert error message
      alert("Please enter npub");
    }

    window.dispatchEvent(new Event("user"));
    setButtonEnabled(true);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="m-1"
    >
      <Paragraph>To let the site know how to greet you, enter your npub.</Paragraph>
      {message && npub && (
        <div>
          <Tag color={message === "Invalid npub" ? "error" : "success"}>{message}</Tag>
        </div>
      )}
      <Input
        placeholder="npub"
        value={npub}
        onChange={(e) => {
          setNpub(e.target.value);
        }}
        className={message && npub ? "mt-1" : ""}
      />
      <Paragraph className="mt-1">
        Storm.pub supports NIP07 for signing. If you have a NIP07 compatible wallet, you can use it
        to authenticate, and you dont need to enter it here.
      </Paragraph>
      <Paragraph>
        If you don't have a NIP07 compatible extension, you can use your Nsec to authenticate. Your
        Nsec will be stored in your browser. It will never be sent to the server.
      </Paragraph>
      <Input
        placeholder="nsec"
        type="password"
        value={nsec}
        onChange={(e) => {
          setNsec(e.target.value);
        }}
      />
      <Button
        type="primary"
        className="mt-1"
        onClick={storeNpubInLocalStorage}
        disabled={!buttonEnabled}
      >
        Login
      </Button>
    </div>
  );
}
