import React, { useState } from "react";
import "./App.css";
import { App, ConfigProvider, theme } from "antd";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Video from "./Pages/Video/Video";
import Search from "./Pages/Search/Search";
import NavBar from "./Pages/Search/Navbar";
import About from "./Pages/About/About";
import Register from "./Pages/Register/Register";
import Contact from "./Pages/Contact/Contact";
import Donate from "./Pages/Donate/Donate";
import Login from "./Pages/Login/Login";
import UserProvider from "./Context/UserProvider";
import Settings from "./Pages/Settings/Settings";
import ChannelPage from "./Pages/Channel/ChannelPage";
import Note from "./Pages/Channel/Note";
import NdkProvider from "./Context/NdkProvider";
import Timeline from "./Pages/Timeline/Timeline";
import Following from "./Pages/Following/Following";
import SocketProvider from "./Context/SocketProvider";
import StandardErrorBoundary from "./Components/StandardErrorBoundary";
import LocationBar from "./Components/LocationBar";
import Post from "./Pages/Post/Post";
import HomeRow from "./Components/HomeRow";
import NostrSearch from "./Pages/NostrSearch/NostrSearch";

export function getApiDomain() {
  if (!process.env.REACT_APP_API_URL) {
    // thow exception
    throw new Error("REACT_APP_API_URL is not defined");
  }
  const apiUrl = process.env.REACT_APP_API_URL;
  return apiUrl;
}

console.log("API URL: ", getApiDomain());

function StormPub() {
  return (
    <div
      style={{
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
      }}
    >
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "	#ff4d00",
          },
        }}
      >
        <UserProvider>
          <NdkProvider>
            <SocketProvider>
              <App className="App">
                <Router>
                  <NavBar />
                  <LocationBar />
                  <StandardErrorBoundary>
                    <HomeRow>
                      <Routes>
                        <Route path="/" element={<Timeline />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/v4v" element={<Donate />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/watch" element={<Video />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/npub/:npub" element={<ChannelPage />} />
                        <Route path="/note/:noteId" element={<Note />} />
                        <Route path="/following/:npub" element={<Following />} />
                        <Route path="/post" element={<Post />} />
                        <Route path="/nostrsearch" element={<NostrSearch />} />
                        <Route
                          path="/throwError"
                          element={() => {
                            throw new Error("This is a test error");
                          }}
                        />
                        <Route path="*" element={<>404 not found</>} />
                      </Routes>
                    </HomeRow>
                  </StandardErrorBoundary>
                </Router>
              </App>
            </SocketProvider>
          </NdkProvider>
        </UserProvider>
      </ConfigProvider>
    </div>
  );
}

export default StormPub;
