export async function makeReply(socket, reply, publicKey, privateKey, hexNoteId) {
  return makeEvent(socket, reply, 1, publicKey, privateKey, [["e", hexNoteId]]);
}

// likes are kind 7 with content '+' and tags
// [["e", hexNoteId], ["p", hexPubKey]]
// of the note that the user is reacting to
export async function makeLike(socket, publicKey, privateKey, hexNoteId, hexPubKey) {
  return makeEvent(socket, "+", 7, publicKey, privateKey, [
    ["e", hexNoteId],
    ["p", hexPubKey],
  ]);
}

// reposts are kind 6. the content of the repost event is the stringified
// json of the reposted note. the repost event must include an "e" tag with the hex id
// of the note that is being reposted. the tag must include the relay URL as its third entry
// to indictate where it can be fetched.
// the repost should include a "p" tag with the "hexPubKey" of the event being reposted
export async function makeRepost(
  socket,
  publicKey,
  privateKey,
  hexNoteId,
  hexPubKey,
  relayURL,
  post
) {
  let content = JSON.stringify(post);
  let tags = [
    ["e", hexNoteId, relayURL],
    ["p", hexPubKey],
  ];
  return makeEvent(socket, content, 6, publicKey, privateKey, tags);
}

export async function makeNote(socket, note, publicKey, privateKey) {
  return makeEvent(socket, note, 1, publicKey, privateKey, []);
}

export async function makeEvent(socket, note, kind, publicKey, privateKey, tags) {
  // check to see if window.nostr exists. if it does, use it to sign the message
  // async window.nostr.signEvent(event: { created_at: number, kind: number, tags: string[][], content: string }): Event // takes an event object, adds `id`, `pubkey` and `sig` and returns it
  if (window.nostr) {
    let now = Math.floor(new Date().getTime() / 1000);
    let newevent = {
      created_at: now,
      kind: kind,
      tags: tags,
      content: note,
    };
    let event;

    try {
      event = await window.nostr.signEvent(newevent);
    } catch (error) {
      alert(
        "Error signing event. Make sure that your Nostr extension is configured properly with a secret key.\n\n" +
          error.message
      );
      return;
    }

    let sendable = JSON.stringify(["EVENT", event]);
    socket.send(sendable);
    const formattedEvent = {
      id: event.id,
      pubKey: event.pubkey,
      created_at: event.created_at,
      kind: event.kind,
      tags: event.tags,
      content: event.content,
      sig: event.sig,
    };
    return formattedEvent;
  } else {
    // lets do this the manual way!
    let now = Math.floor(new Date().getTime() / 1000);
    let newevent = [0, publicKey, now, kind, tags, note];
    let message = JSON.stringify(newevent);
    let msghash = window.bitcoinjs.crypto.sha256(message).toString("hex");
    let value = await window.nobleSecp256k1.schnorr.sign(msghash, privateKey);
    let sig = value;
    let verified = window.nobleSecp256k1.schnorr.verify(sig, msghash, publicKey);
    if (verified) {
      let fullevent = {
        id: msghash,
        pubkey: publicKey,
        created_at: now,
        kind: kind,
        tags: tags,
        content: note,
        sig: sig,
      };
      let sendable = JSON.stringify(["EVENT", fullevent]);
      socket.send(sendable);
    }
    return {
      id: msghash,
      created_at: now,
      content: note,
      pubKey: publicKey,
    };
  }
}
