import React, { useContext, useState } from "react";
import UserContext from "../Context/UserContext";
import SocketContext from "../Context/SocketContext";
import { convertStringToHex } from "../Functions/Conversions";
import { makeReply } from "../Functions/Notes";
import Button from "./Button";
import Input from "./Input";

export default function PostYourReply({ post, addNewReply }) {
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const [replyText, setReplyText] = useState(null);

  async function postComment() {
    try {
      const pubKey = convertStringToHex(user.npub);
      const privateKey = user.nsec ? convertStringToHex(user.nsec) : null;
      const hexNoteId = post.id;

      const r = await makeReply(socket, replyText, pubKey, privateKey, hexNoteId);
      addNewReply(r);
    } catch (error) {
      console.error(error);
    } finally {
      setReplyText("");
    }
  }

  return (
    <div>
      {user && (
        <div className="border-bottom">
          <div className="m-1">
            <div className="flex-row mb-1">
              <img
                src={user.image}
                alt={user.displayName}
                style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
              />
              <Input
                placeholder="Post your reply"
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                onPressEnter={(e) => {
                  postComment();
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button type={"primary"} onClick={postComment}>
                Post
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
