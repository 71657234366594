import React, { useContext, useEffect, useState } from "react";
import NdkContext from "../Context/NdkContext";
import { convertStringToHex } from "../Functions/Conversions";
import fetchEvents from "../Functions/FetchEvents";

export default function useZaps(noteId) {
  const [zaps, setZaps] = useState([]);
  const { ndk, setNdk } = useContext(NdkContext);

  // TODO refactor to use fetchEvents
  useEffect(() => {
    async function fetchZaps() {
      const hex = convertStringToHex(noteId);
      const filter = {
        kinds: [9735],
        "#e": [hex],
      };

      try {
        const events = await ndk.fetchEvents(filter);
        const eventArray = Array.from(events);
        if (eventArray.length === 0) {
          return;
        }
        let formattedZaps = [];
        eventArray.forEach((event) => {
          if (event.kind === 9735) {
            const invoices = event.tags.filter((tag) => tag[0] === "bolt11").map((tag) => tag[1]);
            let sum = 0;
            for (let invoice of invoices) {
              // if invoice starts with lnbc its a zap
              if (invoice.startsWith("lnbc")) {
                // parse the invoice. we are looking for a number followed by a single letter
                // this is the amount of the zap
                const amount = invoice.match(/\d+[a-zA-Z]/);

                // get the number and the letter
                let number = amount[0].match(/\d+/);
                const letter = amount[0].match(/[a-zA-Z]/);

                number = number * 100000000;

                switch (letter[0].toLowerCase()) {
                  case "m":
                    number = number * 0.001;
                    break;
                  case "u":
                    number = number * 0.000001;
                    break;
                  case "n":
                    // divide by 1000000
                    number = number * 0.000000001;
                    break;
                  case "p":
                    // divide by 1000000000
                    number = number * 0.000000000001;
                    break;
                  default:
                    break;
                }

                sum += number;
              }
            }

            formattedZaps.push({
              id: event.id,
              content: event.content,
              pubKey: event.pubkey,
              created_at: event.created_at,
              tags: event.tags,
              amount: sum,
            });
          }
        });

        setZaps(formattedZaps);
      } catch (error) {
        console.error(error);
      }
    }

    if (noteId && ndk) {
      fetchZaps();
    }
  }, [noteId, ndk]);

  return zaps;
}
