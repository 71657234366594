import React, { useContext, useEffect } from "react";
import ActionIcon from "./ActionIcon";
import { FaHeart } from "react-icons/fa6";
import { convertStringToHex } from "../../Functions/Conversions";
import UserContext from "../../Context/UserContext";
import { makeLike } from "../../Functions/Notes";
import SocketContext from "../../Context/SocketContext";

export default function LikeButton({ reactions, post }) {
  const { socket } = useContext(SocketContext);
  const { user } = useContext(UserContext);
  const [userPubKey, setUserPubKey] = React.useState(null);
  const [userLikedPost, setUserLikedPost] = React.useState(false);
  const [liked, setLiked] = React.useState(false);

  useEffect(() => {
    if (user) {
      setUserPubKey(convertStringToHex(user.npub));
    }
  }, [user]);

  // reset like status if reactions load after the user liked the post
  useEffect(() => {
    if (reactions.length > 0) {
      setLiked(false);
    }
  }, [reactions]);

  useEffect(() => {
    if (reactions.length > 0 && userPubKey) {
      const userReaction = reactions.find((reaction) => reaction.pubKey === userPubKey);
      if (userReaction) {
        setUserLikedPost(true);
      }
    }
  }, [userPubKey, reactions]);

  async function likeNote() {
    if (userLikedPost) {
      return;
    }

    try {
      const pubKey = convertStringToHex(user.npub);
      const privateKey = user.nsec ? convertStringToHex(user.nsec) : null;
      const hexNoteId = post.id;

      const event = await makeLike(socket, pubKey, privateKey, hexNoteId, post.pubKey);
      setLiked(true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ActionIcon
      icon={<FaHeart />}
      count={reactions.length + (liked ? 1 : 0)}
      onClick={likeNote}
      primary={userLikedPost || liked}
    />
  );
}
