import React, { useEffect, useRef } from "react";
import NostrUsername from "./NostrUsername";

export default function RenderPostContent({ text, navigateToNote, setImageForModal, isVisible }) {
  const videoRef = useRef(null);

  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleStopVideo = () => {
    if (videoRef.current) {
      let isPlaying =
        videoRef.currentTime > 0 &&
        !videoRef.paused &&
        !videoRef.ended &&
        videoRef.readyState > videoRef.HAVE_CURRENT_DATA;
      if (isPlaying) {
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    if (isVisible === true) {
      handlePlayVideo();
    }

    if (isVisible === false) {
      handleStopVideo();
    }
  }, [isVisible]);

  if (typeof text !== "string") {
    return;
  }

  function parseLink(part, index) {
    if (part.startsWith("https://www.youtube.com/watch?v=")) {
      return (
        <iframe
          key={index}
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${part.split("https://www.youtube.com/watch?v=")[1]}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }

    if (part.startsWith("https://youtu.be/")) {
      return (
        <iframe
          key={index}
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${part.split("https://youtu.be/")[1]}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }

    // render images in a square frame
    if (part.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      return (
        <img
          key={index}
          src={part}
          alt="post"
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: "1/1",
            objectFit: "cover",
            borderRadius: "1rem",
          }}
          onClick={() => {
            setImageForModal({ visible: true, image: part });
          }}
        />
      );
    }

    // render mp4 videos with autoplay. only play the video if it is visible
    if (part.match(/\.(mp4)$/) != null || part.match(/\.(mov)$/) != null) {
      return (
        <video
          ref={videoRef}
          key={index}
          autoPlay
          controls
          style={{ width: "100%" }}
          muted
          loop
          playsInline
          preload="auto"
        >
          <source src={part} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    // try to render link previews
    return (
      <a key={index} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    );
  }

  let content = text;

  // split on space, newline, or tab. use () to keep the delimiters
  let tokens = content.split(/([\s\r\n]+)/);
  for (let i = 0; i < tokens.length; i++) {
    let token = tokens[i];

    if (urlRegex.test(token)) {
      tokens[i] = parseLink(token, i);
      continue;
    }

    if (token.startsWith("nostr:")) {
      if (token.startsWith("nostr:npub")) {
        // split part along ":"
        const npub = token.split(":")[1];
        tokens[i] = <NostrUsername npub={npub} />;
        continue;
      }
    }

    tokens[i] = (
      <span key={i} onClick={navigateToNote}>
        {tokens[i]}
      </span>
    );
  }

  return <div>{tokens}</div>;
}
