import React, { useContext, useEffect, useState } from "react";
import { convertHexToNoteId, convertHexToNpub } from "../Functions/Conversions";
import { getCachedProfile, setCachedProfile } from "../Singletons/profileCache";
import NdkContext from "../Context/NdkContext";
import { convertStringToHex } from "../Functions/Conversions";

export default function useProfile(npub, isHex = false) {
  const [channelData, setChannelData] = useState(null);
  const { ndk, setNdk } = useContext(NdkContext);

  useEffect(() => {
    async function fetchProfileData() {
      const pubkey = isHex ? npub : convertStringToHex(npub);
      const u = ndk.getUser({
        pubkey: pubkey, // THIS MUST BE HEX
      });

      const p = await u.fetchProfile();
      if (!p) {
        return;
      }
      const userInfo = {
        bannerUrl: p.banner,
        profileImageUrl: p.image,
        username: p.displayName === null || p.displayName === "" ? p.name : p.displayName,
        description: p.about,
        website: p.website,
        lud16: p.lud16,
        nip05: p.nip05,
        npub: isHex ? convertHexToNpub(npub) : npub,
        pubKey: isHex ? npub : convertStringToHex(npub),
      };

      const id = isHex ? convertHexToNoteId(npub) : npub;
      setCachedProfile(id, userInfo);
      setChannelData(userInfo);
    }

    if (npub) {
      // Check if the profile is already in the cache
      const npubToCheck = isHex ? convertHexToNpub(npub) : npub;
      let cachedProfile = getCachedProfile(npubToCheck);
      if (cachedProfile) {
        setChannelData(cachedProfile);
        return;
      }
    }

    if (ndk) {
      if (npub) {
        if (typeof npub === "string" && npub.length > 0) {
          const isValidNpub = npub.length && npub.startsWith("npub") && npub.length === 63;
          const isValidHex = isHex && npub.length && npub.length === 64;
          if (isValidHex || isValidNpub) {
            fetchProfileData();
          } else {
            throw new Error("Invalid npub length");
          }
        }
      } else {
        setChannelData(null);
      }
    }
  }, [npub, ndk, isHex]);

  return channelData;
}
