import React from "react";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import moment from "moment";

export default function Video({ video }) {
  const navigate = useNavigate();

  return (
    <div className="mt-1 mr-1 ml-1 card">
      <div
        style={{
          position: "relative",
        }}
      >
        <YouTube
          videoId={video.youtubeId}
          opts={{
            width: "100%",
            height: "300px",
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          }}
        />
        <div
          onClick={() => {
            navigate(`/watch?v=${video.youtubeId}`);
          }}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
        ></div>
      </div>
      <div>
        <Typography.Title level={5}>{video.title}</Typography.Title>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Typography.Text className="text-secondary">{video.views} views</Typography.Text>
          <Typography.Text className="text-secondary">
            {moment(video.publishedAt).fromNow()}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}
