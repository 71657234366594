import { Button, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

export default function Settings() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className="m-1">
      {user && (
        <>
          <img
            src={user.image}
            alt={user.displayName}
            style={{ width: "5rem", height: "5rem", borderRadius: "50%" }}
          />
          <div>
            <Title level={3}>{user.displayName}</Title>
            <Title level={5}>{user.name}</Title>
            <Paragraph>{user.about}</Paragraph>
            {user.lud16 && (
              <Paragraph>
                <>
                  <b>Lud16:</b> {user.lud16}
                </>
              </Paragraph>
            )}
            {user.nip05 && (
              <Paragraph>
                <>
                  <b>Nip05:</b> {user.nip05}
                </>
              </Paragraph>
            )}
            <Paragraph>{user.npub}</Paragraph>
          </div>
        </>
      )}
      <Button
        onClick={() => {
          localStorage.removeItem("user");

          // dispatch a user event to update the user context
          window.dispatchEvent(new Event("user"));
          navigate("/login");
        }}
      >
        Logout
      </Button>
    </div>
  );
}
