import React from "react";
import { Typography, Modal } from "antd";
import { MdOutlineClear } from "react-icons/md";

export default function RecentSearches({
  recentSearches,
  clearReacentSearches,
  handleSearch,
  setIsModalOpen,
  showClearHistoryModal,
  width,
  maxWidth,
  isModalOpen,
}) {
  return (
    <>
      <div
        className="m-1"
        style={{
          width: width,
          maxWidth: maxWidth,
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          className="mb-1"
        >
          <Typography.Paragraph
            style={{
              margin: 0,
            }}
          >
            History
          </Typography.Paragraph>
          <MdOutlineClear
            style={{
              cursor: "pointer",
            }}
            size={20}
            onClick={() => {
              if (showClearHistoryModal) {
                setIsModalOpen(true);
              } else {
                clearReacentSearches();
              }
            }}
          />
        </div>

        {recentSearches.map((search, index) => (
          <div key={index}>
            <Typography.Paragraph
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handleSearch(search);
              }}
            >
              {search}
            </Typography.Paragraph>
          </div>
        ))}
      </div>
      <Modal
        open={isModalOpen}
        onOk={() => {
          clearReacentSearches();
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <Typography.Title level={2}>Interesting...🤨</Typography.Title>
        <Typography.Paragraph>Dont worry, it'll be our little secret.</Typography.Paragraph>
      </Modal>
    </>
  );
}
