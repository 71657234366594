import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Typography, QRCode } from "antd";
import { InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { getApiDomain } from "../../App";

const { Title, Paragraph } = Typography;

export default function DisplayInvoice({
  lightningAddress,
  makeItRain = false,
  verified,
  username,
  defaultValue,
  videoId,
  donation = false,
}) {
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [invoice, setInvoice] = useState("");
  const [value, setValue] = useState(defaultValue);

  const navigate = useNavigate();

  useEffect(() => {
    async function createInvoice() {
      setInvoiceLoading(true);

      try {
        const response = await axios.post(getApiDomain() + "/createInvoice", {
          amount: value,
          lightningAddress: lightningAddress,
          videoId: videoId,
          donation: donation,
        });
        setInvoice(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setInvoiceLoading(false);
      }
    }

    if (verified) {
      createInvoice();
    }
  }, [value, lightningAddress, verified, defaultValue]);

  return (
    <>
      {verified ? (
        <>
          {invoice !== "" ? (
            <div>
              {makeItRain && (
                <>
                  <SatButton setValue={setValue} amount={69} text="Few" />
                  <SatButton setValue={setValue} amount={420} text="Pleb" />
                  <SatButton setValue={setValue} amount={690} text="Steady Lads" />
                  <SatButton setValue={setValue} amount={4200} text="Deploying More Capital" />
                  <SatButton setValue={setValue} amount={6150} text="Generational Wealth" />
                  <div style={{ display: "flex" }}>
                    <Typography.Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                      ⚡
                    </Typography.Paragraph>
                    <InputNumber
                      defaultValue={69}
                      value={value}
                      onChange={(e) => {
                        setValue(e);
                      }}
                    />
                    <Typography.Title level={5} style={{ marginTop: "auto", marginBottom: "auto" }}>
                      &nbsp;sats
                    </Typography.Title>
                  </div>
                </>
              )}

              <div style={{ display: "flex", flexDirection: "column", maxWidth: "20rem" }}>
                {invoiceLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    className={makeItRain ? "mt-1" : ""}
                  />
                ) : (
                  <>
                    <QRCode
                      type="svg"
                      value={invoice}
                      className={makeItRain ? "mt-1" : ""}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <Button
                      className="mt-1 mb-1"
                      onClick={() => {
                        navigator.clipboard.writeText(invoice);
                      }}
                    >
                      Copy Invoice
                    </Button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              style={{ margin: "auto" }}
            />
          )}
        </>
      ) : (
        <>
          <Title level={4}>{username} has not registered their YouTube channel on Storm.pub!</Title>
          <Paragraph>
            Once they register, you'll be able to send them sats directly to their wallet.
          </Paragraph>
          <Paragraph>
            Please tell <b>{username}</b> to go to Storm.pub/register to register their channel!
          </Paragraph>
          <Button
            onClick={() => {
              navigator.clipboard.writeText("https://storm.pub/register");
            }}
          >
            Copy Link To Register
          </Button>
          <Paragraph className="mt-1">
            In the meantime, if you feel like you have received value from Storm.pub, you can send
            sats to the developer by clicking the button below.
          </Paragraph>
          <Button
            onClick={() => {
              navigate("/v4v");
            }}
          >
            Donate
          </Button>
        </>
      )}
    </>
  );
}

function SatButton({ amount, text, setValue }) {
  return (
    <Button
      type="primary"
      shape="round"
      size="default"
      className="mb-1 mr-1"
      onClick={() => {
        setValue(amount);
      }}
    >
      <b>{amount}</b>&nbsp;{text}
    </Button>
  );
}
