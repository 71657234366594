import React from "react";
import { Input } from "antd";

export default function TextArea({ placeholder, value, onChange, style }) {
  return (
    <Input.TextArea
      style={{
        width: "100%",
        height: "3rem",
        borderRadius: "1rem",
        border: "none",
        padding: "1rem",
        ...style,
      }}
      placeholder={placeholder}
      autoSize={{ minRows: 3, maxRows: 6 }}
      value={value}
      onChange={onChange}
    />
  );
}
