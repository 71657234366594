import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Context/UserContext";
import ProfileImageAndUsername from "../../Components/ProfileImageAndUsername";
import useProfile from "../../Hooks/useProfile";
import { convertStringToHex } from "../../Functions/Conversions";
import { makeNote } from "../../Functions/Notes";
import SocketContext from "../../Context/SocketContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TextArea from "../../Components/TextArea";
import Button from "../../Components/Button";

export default function WritePost() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [text, setText] = useState("");
  const profile = useProfile(user?.npub);
  const { socket } = useContext(SocketContext);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      user &&
      (location.pathname === "/" ||
        location.pathname === `/npub/${user.npub}` ||
        location.pathname === `/post`)
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [user]);

  async function sendPost() {
    if (!text) {
      return;
    }
    try {
      const pubKey = convertStringToHex(user.npub);
      const privateKey = user.nsec ? convertStringToHex(user.nsec) : null;

      const post = await makeNote(socket, text, pubKey, privateKey);
      if (post) {
        setText("");
        if (location.pathname === "/post") {
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {show && (
        <div className="border-bottom">
          <div className="m-1 ">
            {profile && <ProfileImageAndUsername profile={profile} />}

            <TextArea
              placeholder="What's on your mind?"
              autoSize={{ minRows: 3, maxRows: 6 }}
              className="mt-1"
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{
                marginTop: "1rem",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button className="mt-1" type="primary" onClick={sendPost} disabled={!text}>
                Post
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
