import React, { useContext, useEffect, useState } from "react";
import NdkContext from "../Context/NdkContext";
import { convertStringToHex } from "../Functions/Conversions";
import fetchEvents from "../Functions/FetchEvents";

export default function useNote(noteId) {
  const [note, setNote] = useState(null);
  const { ndk } = useContext(NdkContext);

  useEffect(() => {
    async function getNoteData(noteId) {
      const noteHex = convertStringToHex(noteId);
      const filter = {
        kinds: [1],
        ids: [noteHex],
      };

      const formattedNotes = await fetchEvents(ndk, filter);
      if (formattedNotes.length > 0) {
        setNote(formattedNotes[0]);
      }
    }

    if (ndk) {
      if (noteId) {
        getNoteData(noteId);
      } else {
        setNote(null);
      }
    }
  }, [ndk, noteId]);

  return note;
}
