import React, { useContext, useEffect } from "react";
import NdkContext from "../Context/NdkContext";
import { convertStringToHex } from "../Functions/Conversions";
import fetchEvents from "../Functions/FetchEvents";

export default function useReactions(noteId) {
  const [reactions, setReactions] = React.useState([]);
  const { ndk, setNdk } = useContext(NdkContext);

  useEffect(() => {
    async function fetchReactions() {
      let hex = convertStringToHex(noteId);

      const filter = {
        kinds: [7],
        "#e": [hex],
      };

      const formattedEvents = await fetchEvents(ndk, filter);
      let formattedReactions = [];
      formattedEvents.forEach((event) => {
        if (event.content && event.content === "+") {
          formattedReactions.push(event);
        }
      });

      setReactions(formattedReactions);
    }

    if (noteId && ndk) {
      fetchReactions();
    }
  }, [noteId, ndk]);

  return reactions;
}
