import React, { useState } from "react";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import SearchValue from "../Hooks/searchValue";

const { Search } = Input;
export default function SearchBar({ width = "15rem", placeHolder = "", handleSearch = () => {} }) {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  return (
    <Search
      placeholder={placeHolder ? placeHolder : "Paste YouTube share link here"}
      enterButton
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      onSearch={(value) => {
        if (handleSearch) {
          handleSearch(value);
        }
        const resource = SearchValue(value);
        navigate(resource);
        setSearchText("");
      }}
      style={{ width: width }}
    />
  );
}
