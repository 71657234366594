import React from "react";
import { getApiDomain } from "../App";
import axios from "axios";

export default function useVideos(npub) {
  const [videos, setVideos] = React.useState(null);

  React.useEffect(() => {
    async function fetchVideos() {
      const response = await axios.get(`${getApiDomain()}/videos`, {
        params: {
          npub,
        },
      });
      setVideos(response.data);
    }

    fetchVideos();
  }, [npub]);

  return videos;
}
