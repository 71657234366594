import React, { useContext, useState } from "react";
import Logo from "./Logo";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import useScreenSize from "../../UseComponents/UseScreenSize";
import { TiThMenu } from "react-icons/ti";
import UserContext from "../../Context/UserContext";

export default function NavBar() {
  const [showDrawer, setShowDrawer] = useState(false);

  const screenSize = useScreenSize();

  return (
    <>
      <div
        className="navbar border-bottom"
        style={{
          height: "2rem",
          boxShadow: "10px 10px 10px 10px rgba(0,0,0,1)", // TODO make it show over the banner image on npub page
        }}
      >
        <Logo navBar displayText={false} />
        {screenSize === "xs" || screenSize === "s" ? (
          <TiThMenu
            size={20}
            onClick={() => {
              setShowDrawer(true);
            }}
          />
        ) : (
          <MenuButtons vertical={false} />
        )}
      </div>

      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        size="default"
        width={"15rem"}
      >
        <MenuButtons setShowDrawer={setShowDrawer} />
      </Drawer>
    </>
  );
}

function MenuButtons({ vertical = true, setShowDrawer = function (test) {} }) {
  const { user, setUser } = useContext(UserContext);

  function hideDrawer() {
    setShowDrawer(false);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: vertical ? "column" : "row",
      }}
    >
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/"
        onClick={hideDrawer}
      >
        Timeline
      </NavButton>
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/search"
        onClick={hideDrawer}
      >
        Search
      </NavButton>
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/about"
        onClick={hideDrawer}
      >
        About
      </NavButton>
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/v4v"
        onClick={hideDrawer}
      >
        V4V
      </NavButton>
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/Contact"
        onClick={hideDrawer}
      >
        Contact
      </NavButton>
      <NavButton
        style={{
          textAlign: vertical ? "right" : "center",
        }}
        to="/register"
        onClick={hideDrawer}
      >
        Register
      </NavButton>
      {user ? (
        <>
          <NavButton
            style={{
              textAlign: vertical ? "right" : "center",
            }}
            to="/settings"
            onClick={hideDrawer}
          >
            Settings
          </NavButton>
          <NavButton
            style={{
              textAlign: vertical ? "right" : "center",
            }}
            to={"/npub/" + user.npub}
            onClick={hideDrawer}
          >
            My Channel
          </NavButton>
        </>
      ) : (
        <NavButton
          style={{
            textAlign: vertical ? "right" : "center",
          }}
          to="/login"
          onClick={hideDrawer}
        >
          Login
        </NavButton>
      )}
    </div>
  );
}

function NavButton({ to = "/", children, onClick, style = {} }) {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        width: "100%",
        ...style,
      }}
      type="text"
      onClick={() => {
        navigate(to);
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </Button>
  );
}
