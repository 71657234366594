// UserProvider.js
import React, { useState, useEffect } from "react";
import NdkContext from "./NdkContext";
import NDK from "@nostr-dev-kit/ndk";

const NdkProvider = ({ children }) => {
  const [ndk, setNdk] = useState(null);

  useEffect(() => {
    async function initializeNdk() {
      const ndk = new NDK({
        explicitRelayUrls: ["wss://relay.damus.io", "wss://purplepag.es"],
      });
      // Now connect to specified relays
      await ndk.connect();
      setNdk(ndk);
    }

    initializeNdk();
  }, []);

  return <NdkContext.Provider value={{ ndk, setNdk }}>{children}</NdkContext.Provider>;
};

export default NdkProvider;
