import { Typography } from "antd";
import { useEffect, useState } from "react";
import NDK from "@nostr-dev-kit/ndk";

export default function Comment({ pubKey, content }) {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    async function getProfile(pubKey) {
      if (!pubKey) {
        return;
      }

      try {
        const ndk = new NDK({
          explicitRelayUrls: ["wss://relay.damus.io", "wss://purplepag.es"],
        });
        // Now connect to specified relays
        await ndk.connect();
        const u = ndk.getUser({
          pubkey: pubKey,
        });
        const p = await u.fetchProfile();
        const userInfo = {
          displayName: p.displayName ?? p.name,
          image: p.image,
        };

        setUserInfo(userInfo);
      } catch (error) {
        console.error(error);
      }
    }

    getProfile(pubKey);
  }, [pubKey]);

  return (
    <>
      {userInfo.displayName && userInfo.image && content && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            marginBottom: "10px",
          }}
        >
          <div>
            <img
              src={userInfo.image}
              alt="profile"
              style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
            />
          </div>
          <div
            style={{
              marginLeft: "1rem",
            }}
          >
            {userInfo.displayName ? (
              <Typography.Title level={5}>{userInfo.displayName}</Typography.Title>
            ) : (
              <Typography.Title
                level={5}
                style={{
                  color: "gray",
                }}
              >
                Anonymous
              </Typography.Title>
            )}
            <Typography.Paragraph>{content}</Typography.Paragraph>
          </div>
        </div>
      )}
    </>
  );
}
