import { Button, Tag } from "antd";
import { AiFillLike } from "react-icons/ai";
import { GiHeavyRain, GiOstrich } from "react-icons/gi";
import { FaCloudShowersWater } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { Row, Col } from "antd";
import useScreenSize from "../../UseComponents/UseScreenSize";
import Npub from "../../Components/Npub";

const { Paragraph } = Typography;

export default function TitleUserButtonSection({
  title,
  profileImageUrl,
  username,
  subscribers,
  likeButtonClicked,
  rainButtonClicked,
  verified,
  npub,
}) {
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  function navigateToChannel() {
    if (npub) {
      navigate(`/npub/${npub}`);
    }
  }

  return (
    <div className="card">
      <Typography.Title level={3}>{title}</Typography.Title>
      <Row>
        <Col xs={{ flex: "100%" }} sm={{ flex: "50%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              cursor: "pointer",
            }}
            onClick={navigateToChannel}
          >
            <img
              style={{
                width: "3rem",
                height: "3rem",
                borderRadius: "50%",
              }}
              src={profileImageUrl}
              alt="profile"
            />
            <div>
              <Typography.Title level={5} style={{ margin: 0 }} onClick={navigateToChannel}>
                {username} {verified && "⚡"} {npub && "✅"}
              </Typography.Title>
            </div>
          </div>
        </Col>
        <Col xs={{ flex: "100%" }} sm={{ flex: "50%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: screenSize === "xs" ? "start" : "end",
              marginTop: screenSize === "xs" ? "1rem" : "",
            }}
          >
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<AiFillLike />}
              onClick={likeButtonClicked}
            ></Button>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<FaCloudShowersWater />}
              className="ml-1"
              onClick={rainButtonClicked}
            ></Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
