import { Typography } from "antd";
import React from "react";

export default function ErrorPage() {
  return (
    <div
      className="m-1"
      style={{
        color: "white",
      }}
    >
      <Typography.Title>Oops! Something went wrong!</Typography.Title>
      <Typography.Paragraph>Try refreshing the page or come back later.</Typography.Paragraph>
    </div>
  );
}
