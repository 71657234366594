import { useContext, useEffect, useState } from "react";
import NdkContext from "../Context/NdkContext";
import { convertStringToHex } from "../Functions/Conversions";
import fetchEvents from "../Functions/FetchEvents";

const hexToBytes = (hex) => Uint8Array.from(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

export const bytesToHex = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

export default function useReplies(noteId) {
  const [replies, setReplies] = useState([]);
  const { ndk, setNdk } = useContext(NdkContext);

  useEffect(() => {
    async function fetchComments() {
      setReplies([]);

      let hex = convertStringToHex(noteId);

      const filter = {
        kinds: [1],
        "#e": [hex],
      };

      const formattedEvents = await fetchEvents(ndk, filter);
      let formattedComments = [];
      formattedEvents.forEach((event) => {
        if (event.content) {
          formattedComments.push(event);
        }
      });

      setReplies(formattedComments);
    }

    if (ndk && noteId) {
      fetchComments();
    }
  }, [noteId, ndk]);

  return replies;
}
