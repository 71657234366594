import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Context/UserContext";
import ActionIcon from "./ActionIcon";
import { FaRetweet } from "react-icons/fa6";
import { convertStringToHex } from "../../Functions/Conversions";
import { makeRepost } from "../../Functions/Notes";
import SocketContext from "../../Context/SocketContext";

export default function RepostButton({ post, reposts }) {
  const { socket } = useContext(SocketContext);
  const { user } = useContext(UserContext);
  const [userPubKey, setUserPubKey] = React.useState(null);
  const [userRepostedPost, setUserRepostedPost] = useState(false);
  const [reposted, setReposted] = useState(false);

  useEffect(() => {
    if (user) {
      setUserPubKey(convertStringToHex(user.npub));
    }
  }, [user]);

  // reset like status if reactions load after the user liked the post
  useEffect(() => {
    if (reposts.length > 0) {
      setReposted(false);
    }
  }, [reposts]);

  useEffect(() => {
    if (reposts.length > 0 && userPubKey) {
      const userReaction = reposts.find((repost) => repost.pubKey === userPubKey);
      if (userReaction) {
        setUserRepostedPost(true);
      }
    }
  }, [userPubKey, reposts]);

  async function repostNote() {
    if (userRepostedPost) {
      return;
    }

    try {
      const pubKey = convertStringToHex(user.npub);
      const privateKey = user.nsec ? convertStringToHex(user.nsec) : null;
      const hexNoteId = post.id;

      const event = await makeRepost(
        socket,
        pubKey,
        privateKey,
        hexNoteId,
        post.pubKey,
        post.url,
        post
      );
      setReposted(true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ActionIcon
      icon={<FaRetweet />}
      count={reposts.length + (reposted ? 1 : 0)}
      onClick={repostNote}
      primary={userRepostedPost || reposted}
    />
  );
}
